@import "../../variables.scss";

.award-info {
    .row {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
    }

    .awards {
        flex: 1 1 50%;
        display: flex;
        align-items: flex-start;

        .award {
            list-style: none;
            display: flex;

            padding: 0.5rem;
            background-color: $color-7;

            border: 0.4rem solid $color-3;
            border-radius: 0.4rem;

            margin-right: 0.5rem;

            .img {
                background-color: #979846;
                border: 0.2rem solid #979846;
                width: 7.5rem;
                height: 7.5rem;

                display: flex;
                justify-content: center;
                align-items: center;
            }

            img {
                display: block;
                max-width: 7.5rem;
                max-height: 7.5rem;
            }

            @media (max-width: 450px) {
                .img {
                    width: 6rem;
                    height: 6rem;
                }

                img {
                    max-width: 6rem;
                    max-height: 6rem;
                }
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .button {
            margin-top: 1rem;
        }

        @media (max-width: 700px) {
            flex-direction: row;
        }
    }
}
