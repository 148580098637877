@import '../../variables.scss';

.audio-player {

    font-size: 1.2rem;

    display: flex;
    flex-direction: row;
    justify-content: center;

    .buttons {
        flex: 0 1 20%;

        display: flex;
    }

    .player-button {
        display: block;

        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;

        width: 1em;
        height: 1em;

        margin: 0 .25em;

        cursor: pointer;

        &.play {
            background-image: url('/assets/images/player/play.png');
        }

        &.stop {
            background-image: url('/assets/images/player/stop.png');
        }

        &.pause {
            background-image: url('/assets/images/player/pause.png');
        }
    }


    .time-controlls {
        height: 1em;
        position: relative;
        flex: 0 1 80%;

        input {
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        .axis {
            height: 0.2em;
            position: absolute;
            background: $player-color;

            width: 100%;
            top: 0.4em;
            bottom: 0.4em;

            pointer-events: none;
        }

        .marker {
            pointer-events: none;
            position: absolute;
            top: 0;
            bottom: 0;

            background: $player-color;

            left: 0;
            width: 5px;
        }
    }

    .timeinfo {
        flex: 0 1 60%;

        display: flex;

    }

    .label {
        flex: 0 1 20%;
        text-align: center;
    }
}
