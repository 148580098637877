.task-404 {
    .manual {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .step {
        margin-bottom: 1rem;
        width: 100%;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        height: 100%;
    }

    .step-with-img {
        flex: 0 0 100%;

        img {
            display: block;
            flex: 1 1 100%;
            max-width: 100%;
        }

        @media (max-width: 600px) {
            flex: 0 0 100%;
        }
    }

    .combo-step {
        flex: 0 0 100%;

        height: 18rem;
        display: flex;
        flex-direction: column;

        .steps {
            display: flex;
        }
    }

    .left-wrapper {
        padding-right: 2%;
    }

    .right-wrapper {
        padding-left: 2%;
    }

    p {
        text-align: justify;
        text-transform: uppercase;

        margin: 0 0 1rem;

        font-size: 0.8rem;
        font-weight: bold;

        text-align: center;
    }

    img {
        margin: 0 auto;
        max-width: 600px;
        height: 100%;
    }
}
