.welcome-screen {
    .screen-container {
        display: flex;
        flex-direction: column;
    }

    h1 {
        font-size: 2.5rem;
        text-align: center;
        margin: 1rem 0;
    }

    .buttons {
        display: flex;
        justify-content: center;
        justify-content: space-evenly;

    }

    strong {
        font-weight: bold;
    }

    .img-camera {
        width: 50%;
        display: block;
        margin: 0 auto 1rem;
    }

    .img-poi {
        width: 30%;
        display: block;
        margin: 0 auto 2rem;
    }
}
