@import '../../variables.scss';

.achievement-info {

    h2 strong {
        color: $color-4;
        font-weight: normal;
    }

    .row {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
    }

    .achievements {
        flex: 1 1 50%;
        display: flex;
        align-items: flex-start;
    }

    .achievement {
        list-style: none;
        display: flex;

        padding: .5rem;
        background-color: $color-7;

        border: .4rem solid $color-3;
        border-radius: .8rem;


        .img {
            width: 13.75rem;
            height: 13.75rem;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            display: block;
            max-width: 12rem;
            max-height: 12rem;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .button {
            margin-top: 1rem;
        }
    }
}
