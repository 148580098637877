@import '../../../../variables.scss';

.task-304 {

    .item {
        background: $text-box-background;

        padding: 1rem 3rem;
        border-radius: 3rem;

        display: flex;
        justify-content: center;
        align-items: stretch;

        border: solid 10px $color-3;
        margin-bottom: .5rem;


        overflow: hidden;

        &.correct {
            border-color: $correct-color;
        }

        &.wrong {
            border-color: $wrong-color;

        }

        .item-img {
            flex: 0 0 20%;
            margin-right: 5%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
            }
        }

        .bin-img {
            flex: 0 0 15%;
            margin-right: 12%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
            }
        }

        .arrow {
            flex: 0 0 10%;
            margin-right: 11%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
            }
        }

        .buttons {
            flex: 0 0 30%;
            display: flex;
            justify-content: space-between;

            input {
                width: 100%;
                outline: none;
            }
        }

        .img-btn {

            flex: 0 0 48%;

            background: transparent;
            border: none;
            cursor: pointer;
            outline: none;

            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @media (max-width: 600px) {
            padding: .5rem 1rem;
            border-radius: 2rem;
            border-width: 4px;
        }
    }
}
