@import "../../../../variables.scss";

$border-color: $color-3;

.task-204 {
    .sentence {
        display: flex;
        flex-wrap: wrap;
    }

    .letter {
        text-transform: uppercase;
        font-weight: 500;
    }

    .word {
        display: flex;
        flex: 0 1 auto;
    }

    .piece {
        &:last-child {
            .cell {
                border-right: solid 2px $border-color;
            }

            .cell.empty {
                border-right-color: transparent;
                border-left: solid 1px $border-color;
                border-bottom: solid 1px $border-color;
            }
        }

        &:first-child {
            .cell {
                border-left: solid 2px $border-color;
            }
        }
    }

    .cell {
        position: relative;

        height: 1.5rem;
        width: 1.5rem;

        background: $text-box-background;

        display: flex;

        justify-content: center;
        align-items: center;

        border: solid 1px $border-color;

        &:last-child {
            border-bottom-width: 2px;
        }

        &:first-child {
            border-top-width: 2px;
        }

        &.empty {
            border-color: transparent;
            background-color: transparent;
        }
    }

    .symbol {
        display: block;
        width: 1rem;
    }

    .input {
        width: 90%;
        height: 90%;

        padding: 0;
        margin: 0;

        font-size: 1rem;
        text-transform: uppercase;
        text-align: center;

        background: transparent;
        border: none;
    }

    .code-key {
        margin: 2rem 0;
        font-size: .7rem;
        font-weight: 500;

        .cell {
            height: 1rem;
            width: 1.1rem;
        }

        .symbol {
            display: block;
            width: .7rem;
        }

        .word {
            flex-wrap: wrap;
        }
    }

    .password {
        .sentence {
            margin: 1rem 0;
        }

        &.correct {
            .cell:last-child {
                background: $correct-color;
            }
        }

        // &.incorrect {
        //     .cell:last-child {
        //         background: $wrong-color;
        //     }
        // }
    }
}
