@import '../../variables.scss';

.logos,
.logos2,
.logos3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;

    & > * {
        flex: 0 0 25%;
    }

    .label {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        text-align: left;

        h3 {
            font-size: 0.6rem;
            font-weight: normal;
        }
    }

    .logo {
        position: relative;
        a {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            max-width: 90%;
            margin: 0 auto;

            img {
                display: block;
                width: 100%;
            }
        }
    }
}

.logos {
    @media (max-width: 800px) {
        flex-wrap: wrap;

        .label {
            flex: 0 0 100%;
        }

        .logo {
            flex: 0 0 33%;
        }
    }
}

.logos2 {
    .label {
        flex-basis: 41%;
        h3 {
            padding: 0;
            text-align: right;
        }
    }

    .logo {
        &.park {
            flex-basis: 25%;
            margin-left: 10%;
        }

        &.ccr {
            flex-basis: 17%;
            margin-left: 8%;
        }
    }
}

.logos3 {
    .label {
        flex: 0 0 13.5%;
    }

    .text-info {
        flex: 0 0 24%;
        margin-left: 2%;
        font-size: .65rem;

        p {
            margin: 0 0 .5rem;
        }

        a {
            color: $font-color;
            text-decoration: none;

            &.fb {
                padding-left: 1rem;
                background: url('/assets/images/logos/fb.svg') 0 50% no-repeat;
                background-size: auto 100%;
            }
        }
    }

    .logo {
        &.park {
            flex-basis: 25%;
            margin-left: 10%;
        }

        &.ccr {
            flex-basis: 17%;
            margin-left: 0;
        }

        &.electro {
            flex-basis: 17%;
            margin-left: 3%;
        }
    }

    @media (max-width: 900px) {

        flex-wrap: wrap;
        justify-content: center;
        justify-content:space-evenly;

        .label {
            flex-basis: 100%;
            padding: 1rem 0;
        }

        .logo {
            flex-basis: 40% !important;
            margin: 1rem 0 !important;
        }

        .text-info {
            flex-basis: 40% !important;
            margin: 1rem 0 !important;
        }
    }
}
