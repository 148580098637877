@import '../../variables.scss';

.layout-box {
    $border-width: 10px;

    width: calc(90% - #{$border-width} * 2);
    max-width: 1295px;

    margin: 1.5rem 0;

    background: $box-background-color;
    border: $border-width solid $box-border-color;
    border-radius: 20px;

    position: relative;

    .inner {
        margin: 5rem auto;
        width: 100%;

        @media (max-width: 800px) {
            margin: 10% auto;
        }
    }

    &:last-child {
        margin-bottom: 3em;
    }

    &:first-child {
        margin-top: 3em;
    }

    &.bg-white {
        background: white;
    }

    &.no-padding {
        .inner {
            margin: 0;
            width: 100%;
            max-width: none;
        }
    }
}
