@import '../../variables.scss';

.cookies-overlay {
    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    background: rgba(0,0,0,.5);

    z-index: 100;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media(max-width: 800px) {
        p {
            font-size: 22px;
            padding: 0 5%;
        }

        .button {
            font-size: 22px !important;
        }
    }

    .info {
        font-weight: bold;
    }


    .container {
        background: $text-box-background;
        max-width: 70%;
        max-height: 90%;

        // display: flex;
        // justify-content: center;
        // justify-content: space-evenly;
        // flex-direction: row;
        // align-items: center;
        overflow-y: auto;

        .inner {
            padding: 2rem;
        }

        a {
            color: red;
        }

        ul {
            padding-left: 2rem;

            li {
                margin: .5em 0;
            }
        }

        .buttons {
            width: 100%;
            display: flex;

            justify-content: flex-end;

            @media(max-width: 800px) {
                & {
                    justify-content: center;
                }
            }
        }
    }
}
