@import "../../../../variables.scss";

.task-305 {
    .gameContainer {

        max-width: 58vh;

        canvas {
            width: 100%;
            background: $text-box-background;
            display: block;
            margin: 0 auto;
        }
    }
}
