@import "../../../../variables.scss";

.task-105 {
    .items-list {
        width: 100%;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .widget-item {
        flex: 0 0 25%;

        @media (max-width: 900px) {
            flex-basis: 33%;
        }
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        &.selected .wrapper-2 {
            background: $wrong-color;
        }

        &.locked .wrapper-2 {
            background: $correct-color;
            opacity: 0;
        }

        .wrapper {
            padding: 5%;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .wrapper-2 {
            border-radius: 10%;
            padding: 5%;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            width: 100%;
        }
    }

    .popup-overlay {
        .images {
            display: flex;
            justify-content: center;

            img {
                margin: 1rem;
                height: 20vh;
            }
        }
    }
}
