.geo-trash-task {

    .pickup-zone {
        width: 40%;
        margin: 0 auto;
    }

    .slots {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .bin {
            flex: 0 0 20%;
            justify-content: center;

            .drop-zone {
                display: flex;
                justify-content: center;
                height: 100%;
                align-items: center;
            }

            &.wide {
                flex: 0 0 80%;
                margin: 2rem 0;
            }

            img {
                width: 100%;
            }

            .bin-container {
                width: 75%;
            }
        }
    }

    @media (orientation: landscape) {
        display: flex;
        flex-direction: row;
        align-items: center;

        .pickup-zone {
            flex: 0 0 30%;
        }

        .slots {
            flex: 0 0 70%;
            align-items: center;

            .bin {
                margin: 0;
            }
        }

        .img {
            width: 100%;
        }
    }
}

.geo-task-outro {
    text-align: center;
}
