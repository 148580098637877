@import "../../../../variables.scss";

.task-101 {
    .groups {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 100vh;
        justify-content: center;
        align-items: stretch;
        width: 119vh;
        max-width: 100%;
    }

    .group {
        flex: 0 1 33%;
        display: flex;

        justify-content: center;
        align-items: center;
    }

    .group-item {
        flex: 0 0 48%;
        cursor: pointer;


        .wrapper {
            padding-top: 100%;
            position: relative;
            border-radius: 2vh;
        }

        img {
            position: absolute;
            top: 2%;
            left: 2%;
            right: 2%;
            bottom: 2%;

            display: block;
            max-height: 96%;
            width: 96%;
        }
    }

    .selected .wrapper{
        background-color: $wrong-color;
    }

    .selected.correct .wrapper{
        background-color: $correct-color;
    }
}
