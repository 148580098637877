@import '../../variables.scss';

.rules-page {

    .rules-box {
        background: url('/assets/images/naukowiec.png') 2% bottom no-repeat $body-background-color;
        background-size: 12% auto;

        .inner {
            margin-top: 1rem;
        }

        overflow: hidden;
    }

    h1 {

        padding: 3.5rem 0 .7rem;
        margin-bottom: 1rem;
        position: relative;

        &:after {
            content: '';
            display: inline-block;
            background: red;
            position: absolute;

            top: 0;
            bottom: 0;

            width: 10rem;
            background: url('/assets/images/kapitan-2.png') 50% 50% no-repeat;
            background-size: auto 100%;

        }

    }


    ul {
        padding-left: 9%;
        line-height: 1.3em;
    }

    .legend {
        display: flex;
        justify-content: center;
        margin: .5rem;

        .column {
            display: flex;
            flex-direction: column;

            justify-content: center;
            align-items: center;

            margin: .5rem;

            img {
                height: 1rem;
                width: 1rem;
            }

            label {
                text-transform: uppercase;
                font-size: .8rem;
                margin-top: .3rem;

                text-align: center;
            }
        }

        @media (max-width: 600px) {
            flex-wrap: wrap;
            .column {
                flex: 0 0 50%;
                margin: 0;
            }
        }
    }

    .buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        margin: 1.75rem 0;


    }
}
