.geo-task-introduction {

    display: flex;
    flex-direction: column;



    h1 {
        text-align: center;
        font-size: 2rem;
    }

    .img {
        width: 70%;
        margin: 0 auto;
        position: relative;

        img {
            width: 100%;
            display: block;

            max-height: 100%;
        }
    }

    .description {
        margin: .5em;
    }

    @media (orientation: landscape) {
        flex-direction: row;

        .group {
            flex: 0 0 50%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            justify-content: space-evenly;
        }

        .img {
            width: 100%;
        }
    }
}
