.task-302 {
    .items-menu {
        background: #E8DFC6;

        display: flex;
        flex-direction: column;

        margin-bottom: 1rem;
    }

    .pickup-window-2 {
        position: relative;
        overflow: hidden;

        padding-top: 23%;
    }

    .pickup-window {
        position: absolute;
        top: 0;
        left: 2%;
        right: 2%;
        bottom: 0;

        overflow-x: scroll;

        display: flex;
        align-items: center;
    }

    .pickup-list {
        display: flex;
    }

    .pickup-container {
        flex: 0 0 20%;
    }

    .pickup-container-inner {
        padding: 2%;
    }

    img {
        width: 100%;
    }

    .slots {
        background: url("/assets/images/tasks/302/robot.svg") 50% 0 no-repeat;
        background-size: 100% auto;

        padding-top: 50%;
        position: relative;

        .slot-list {
            position: absolute;
            top: 5%;
            left: 2%;
            right: 2%;
            bottom: 0;

            display: flex;
        }

        .drop-container {
            flex: 0 0 20%;
        }

        .drop-container-inner {
            padding: 2%;
        }
    }

    .controlls {
        position: relative;

        flex-basis: 1.5rem;

        input {
            width: 100%;
            height: 100%;

            opacity: 0;
        }

        .marker {
            position: absolute;
            top: 50%;

            display: block;
            background: #735e23;
            pointer-events: none;
            width: 2rem;
            height: 1rem;

            border-radius: 0.5rem;

            margin-top: -0.5rem;
        }

        &:before {
            content: "";

            display: block;
            width: 100%;
            background: white;
            position: absolute;
            height: 20%;
            top: 40%;
        }
    }
}

.task-302-pick-up {
    display: block;
    width: 100%;
}
