@import '../../../../variables.scss';

.task-202 {

    font-size: .8rem;

    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }


    .item {
        flex: 0 0 50%;

        &:nth-child(2n) .wrapper {
            padding-left: 2%;
        }

        &:nth-child(2n-1) .wrapper {
            padding-right: 2%;
        }

        margin-bottom: 1.5rem;

        @media (max-width: 800px) {
            flex-basis: 100%;
            padding: 0;
        }
    }

    img {
        width: 100%;
    }

    .word {
        display: flex;
        flex-wrap: wrap;

        width: 100%;

        justify-content: center;

        margin: .8em 0;

    }

    .letter {
        display: flex;
        flex-direction: row;
        width: 1.5em;
        height: 1.5em;
        line-height: 1.5em;
        margin: 0 .3em;

        justify-content: center;
        align-items: center;

        border-radius: 50%;

        background: $text-box-background;

    }

    .question {
        margin-top: 2rem;
        margin-bottom: 4rem;
    }

    .answer {
        display: flex;
        flex-direction: column;
        line-height: 1.5rem;


        .row {
            display: flex;
            border-bottom: solid 5px $text-box-background;
        }

        input {
            flex: 1 1 100%;
            font-size: .8rem;
            text-transform: uppercase;
            text-align: center;

            border-color: transparent;
            background: transparent;

            color: $color-4;

            outline: none;
        }

        .tip {
            opacity: .6;
            text-transform: uppercase;
            text-align: center;
            font-size: .7rem;
        }
    }

    .popup-overlay img {
        height: 20vh;
        width: auto;
        display: block;
        float: right;
    }
}
