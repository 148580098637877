@import '../variables.scss';

.geo-game-main {
    width: 100%;
    height: 100%;

    position: relative;

    background: url('/assets/geo/background.jpg') 50% 20% no-repeat;
    background-size: 100% auto;
}

.center-box {
    background-color: $box-background-color;
    border: 3px solid $box-border-color;
    border-radius: 6px;

    padding: 5%;
    width: 80%;
    max-height: 80%;

    overflow-y: auto;
    max-width: 900px;

    @media (orientation: landscape) {
        max-height: 75%;
    }
}

.bottom-box {
    background-color: $box-background-color;
    border: 3px solid $box-border-color;
    border-radius: 6px 6px 0 0;
    border-bottom: none;

    padding: 0 5%;
    max-width: 80%;
    max-height: 80%;

    align-self: flex-end;
}
