$color-1: #643726;
$color-2: #dad7a9;
$color-3: #bf4c1c;
$color-4: #512115;
$color-5: #9C9B45;
$color-6: #E15343;
$color-7: #F4EEC2;


$body-background-color: $color-2;

$box-border-color: $color-1;
$box-background-color: $color-2;
$header-color: $color-3;
$font-color: $color-4;

$player-color: $color-3;

$button-text-color: $color-2;
$button-background-color: $color-3;

$correct-color: $color-5;
$wrong-color: $color-6;

$text-box-background: $color-7;
