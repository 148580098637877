@import "../../../../variables.scss";

.task-205 {

    .item-list {
        margin-bottom: 1rem;
    }

    .item {
        display: flex;

        align-items: center;
        cursor: pointer;
        border-radius: 1rem;

        margin-bottom: .1rem;

        &.error {
            background-color: $wrong-color;
        }

        &.correct {
            background-color: $correct-color;
        }

        input {
            display: none;
        }

        span {
            height: 1rem;
            display: block;

            flex: 0 0 10%;
            background: url("/assets/images/tasks/205/no-selected.svg") 50% 50% no-repeat;
            background-size: contain;
        }

        input:checked + span {
            background-image: url("/assets/images/tasks/205/selected.svg");
        }

        p {
            padding: 0 5% 0 0;
            flex: 1 1 90%;
        }
    }
}
