.task-104 {
    .gameContainer {
        display: block;
        margin: 0 auto;
        max-height: 95vh;
    }

    canvas {
        width: 100%;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
    }
}
