.task-201 {
    .manual {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .step {
        height: 18rem;
        margin-bottom: 1rem;
    }

    .wrapper {
        display: flex;
        flex-direction: column;

        max-width: 800px;
        margin: 0 auto;
    }

    .step-with-img {
        flex: 0 0 100%;

        img {
            display: block;
            max-width: 100%;
            width: 70%;
        }

        @media (max-width: 600px) {
            flex: 0 0 100%;
        }
    }

    .combo-step {
        flex: 0 0 100%;

        height: 18rem;
        display: flex;
        flex-direction: column;

        .steps {
            display: flex;
        }
    }

    p {
        text-align: justify;
        text-transform: uppercase;

        margin: 0 0 1rem;

        font-size: 0.8rem;
        font-weight: 500;

        text-align: center;
    }

    img {
        max-width: 600px;
        height: 100%;
        margin: 0 auto;
    }

    .buttons {
        a {
            margin-bottom: .5rem;
        }
    }
}
