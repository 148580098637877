.privace {

    p {
        line-height: 1.3em;
    }

    ul {
        padding-left: 2rem;

        li {
            margin: .5em 0;
        }
    }
}
