


@keyframes zooming {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.map {

    .generator-link {
        position: absolute;

        top: 2%;
        left: 50%;

        a {
            margin-left: -50%;
        }
    }

    .rules-link {
        position: absolute;

        bottom: 2%;
        right: 2%;
    }

    .task-link {
        display: block;
        position: absolute;
        width: 3%;
        height: 3%;
        background: url("/assets/images/map/task.png") 50% 50% no-repeat;
        background-size: contain;

        &.completed {
            background-image: url("/assets/images/map/completed.png");
        }

        &.avaiable {
            background-image: url("/assets/images/map/avaiable.png");

            animation-name: zooming;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            transform-origin: center;

            &:hover {
                background-image: url("/assets/images/map/hover.png");
            }
        }
    }

    .task-link-t-101 {
        top: 79%;
        left: 40%;
    }

    .task-link-t-102 {
        top: 82.5%;
        left: 22%;
    }

    .task-link-t-103 {
        top: 69%;
        left: 17%;
    }

    .task-link-t-104 {
        top: 59%;
        left: 35.3%;
    }

    .task-link-t-105 {
        top: 50.5%;
        left: 4%;
    }

    .task-link-t-201 {
        top: 67%;
        left: 42.8%;
    }

    .task-link-t-202 {
        top: 57.3%;
        left: 46%;
    }

    .task-link-t-203 {
        top: 35%;
        left: 37%;
    }

    .task-link-t-204 {
        top: 20%;
        left: 29.7%;
    }

    .task-link-t-205 {
        top: 22%;
        left: 15.3%;
    }

    .task-link-t-301 {
        top: 72%;
        left: 49.9%;
    }

    .task-link-t-302 {
        top: 57.3%;
        left: 55.4%;
    }

    .task-link-t-303 {
        top: 33%;
        left: 54.8%;
    }

    .task-link-t-304 {
        top: 16%;
        left: 58%;
    }

    .task-link-t-305 {
        top: 15%;
        left: 78.5%;
    }

    .task-link-t-401 {
        top: 80%;
        left: 74%;
    }

    .task-link-t-402 {
        top: 53%;
        left: 72%;
    }

    .task-link-t-403 {
        top: 40%;
        left: 61.15%;
    }

    .task-link-t-404 {
        top: 47.1%;
        left: 76%;
    }

    .task-link-t-405 {
        top: 56.7%;
        left: 85%;
    }
}
