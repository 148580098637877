.home-page {
    .inner {
        margin-top: 3rem;
    }

    h1 {
        padding: 2.5rem 50% 0.5rem 0;
        margin: 0;

        background: url('/assets/images/kapitan.png') right 35% no-repeat;
        background-size: auto 100%;
    }

    .quote {
        font-style: italic;
    }

    p {
        text-align: justify;
    }

    nav {
        text-align: right;
    }

    .buttons {
        padding: 7rem 0;

        background: url('/assets/images/naukowiec.png') 0 50% no-repeat;
        background-size: auto 100%;
    }

    .start-button {
        font-size: 3rem;
        border-radius: 1rem;
    }

    @media (max-width: 990px) {
        .buttons {
            background: none;
            padding: 1rem 0 0;
        }

        .start-button {
            font-size: 2rem;
        }

        .mobile-container {

            position: relative;


            padding-left: 35%;

            &:before {
                content: '';

                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;

                width: 30%;

                background: url('/assets/images/naukowiec.png') 0 50% no-repeat;
                background-size: contain;
            }
        }
    }
}
