@import "../../../../variables.scss";

.task-405 {

    .cw-container {
        position: relative;
    }

    .cw-cell {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        &.extra-border-left {
            &:before {
                content: '';
                display: block;

                width: 4px;
                height: 100%;
                background: black;

                position: absolute;
                top: 0;
                left: 0;

                transform: translate(-3px,0);
            }
        }

        &.extra-border-right {
            &:before {
                content: '';
                display: block;

                width: 4px;
                height: 100%;
                background: black;

                position: absolute;
                top: 0;
                right: 0;

                transform: translate(3px,0);
            }
        }
    }

    .cell-label {
        text-align: center;
        border: 1px solid transparent;

        font-family: "citrus-gothic", "Inter", sans-serif;
        color: $color-1;

        span {
            display: block;
            width: 90%;
            height: 90%;
        }
    }

    .cell-input {
        background: $text-box-background;
        border: 1px solid $color-3;

        &.correct {
            background: $correct-color;
        }

        &.wrong {
            background: $wrong-color;
        }


        input {
            display: block;
            width: 90%;
            height: 90%;

            padding: 0;

            background: transparent;
            border: none;
            text-align: center;

            font-family: "citrus-gothic", "Inter", sans-serif;
            color: $color-3;
            font-size: .7em;
            line-height: .7em;
        }

    }

    .info {
        display: flex;

        align-items: flex-end;

        .questions {
            flex: 0 0 60%;
        }

        .buttons {
            flex: 0 0 40%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }
}
