@import '../../variables.scss';

.end-game-page {

    .end-game-box {
        flex: 1 1 100%;
        display: flex;
        align-items: stretch;

        .inner {
            margin: 0;
        }

        .standard-insert {
            display: flex;
            height: 100%;
            flex-direction: column;
        }
    }

    .buttons {
        flex: 1 1 100%;

        background:
            url('/assets/images/naukowiec.png') left bottom no-repeat,
            url('/assets/images/kapitan.png') right top no-repeat,
        ;
        background-size: auto 100%, auto 100%;

        padding-left: 10%;
        padding-right: 20%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .end-game-footer {
        flex: 0 1 auto;
    }
}
