@import '../../variables.scss';

.login-page {

    .login-box {
        background: url('/assets/images/kapitan-2.png') no-repeat $body-background-color;
        background-position: left 70% bottom 25%;
        background-size: auto 48% ;
    }

    input {
        border: 5px solid $button-background-color;
        border-radius: .6rem;

        background: $text-box-background;

        font-size: 1rem;
        padding: .5rem;

        outline: none;

    }

    .buttons {
        width: 100%;

        display: flex;
        justify-content: flex-end;
    }
}
