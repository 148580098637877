.task-401 {
    .popup-thumbnails {
        display: flex;
        justify-content: center;

        .img-container {
            width: 15%;
            margin: 1rem 0;

            &:first-child {
                padding-right: 15%;
                position: relative;

                &:after {
                    content: '';

                    position: absolute;
                    top: 0;
                    right: 10%;
                    width: 30%;
                    height: 100%;

                    background: url('/assets/images/tasks/401/arrow.png') 50% 50% no-repeat;
                    background-size: contain;
                }
            }
        }

        .img {
            width: 100%;
            padding-top: 100%;

            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
        }
    }

    canvas {
        width: 100%;
    }
}
