.geo-game-screen {
    width: 100%;
    height: 100%;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
}

.geo-game-background {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow: hidden;

    video {
        position: absolute;

        width: 100%;
        height: 100%;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }

    .background-controlls {
        position: fixed;
        right: .5rem;
        top: .5rem;

        display: flex;
        flex-direction: column;

        z-index: 1000;


        input {
            pointer-events: all;
            height: 3rem;
            width: 4rem;
            margin-bottom: .5rem;

            outline: none;
        }
    }
}

.front-layer {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    pointer-events: none;

    display: flex;
    justify-content: center;
    align-items: center;
}
