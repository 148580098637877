.trash-in-forest {
    display: flex;
    justify-content: center;
    align-items: center;


    img {
        pointer-events: all;
        width: 80%;
    }

    @media (orientation: landscape) {
        img {
            width: 50%;
        }
    }
}
