@import '../../variables.scss';

.popup-overlay {
    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1000;

    background: rgba(0, 0, 0, 0.4);

    display: flex;
    justify-content: center;
    align-items: center;

    .popup-wrapper {
        background: $text-box-background;

        border: 2px solid $box-border-color;
        border-radius: 1rem;
        padding: 1rem;
        flex: 0 1 90%;
        max-width: 1200px;
    }
}
