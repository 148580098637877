
    .task-203 {
        img {
            width: 100%;
        }

        .buttons {
            display: flex;
            justify-content: space-between;
            margin: 1rem 0;
        }

        canvas {
            width: 100%;
        }
    }
