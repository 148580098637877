@import '../../../../variables.scss';

.task-303 {

    .pickup-zone {
        display: flex;
        background: $color-2;
        justify-items: center;

        img {
            width: 100%;
        }
    }

    .item-container {
        flex: 0 0 20%;
    }

    .item-container-inner {
        padding: 5% 2%;
    }

    .slots {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        margin-top: 10rem;

        .bin {
            flex: 0 0 20%;
            justify-content: center;

            .drop-zone {
                display: flex;
                justify-content: center;
                height: 100%;
                align-items: center;
            }

            &.wide {
                flex: 0 0 80%;
                margin: 2rem 0;
            }

            img {
                width: 100%
            }

            .bin-container {
                width: 75%;
            }
        }
    }

    .popup-overlay {
        img {
            display: block;
            margin: 1rem auto;
            max-width: 20%;
        }
    }
}
