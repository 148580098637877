.task {
    min-height: 80vh;

    .task-header {
        .row-1 {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
    }

    .complete-button {
        display: flex;
        justify-content: flex-end;

        margin: 3rem 0;
    }
}
