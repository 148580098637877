@import "../../../../variables.scss";

.task-103 {
    .menu {
        display: flex;

        $padding: 5%;

        border: 3px solid $color-3;
        background: $text-box-background;
        border-radius: 1rem;
        width: calc(100% - #{$padding * 2});

        justify-content: center;

        padding: $padding #{$padding/2};
        margin: #{$padding/2} 0;

        .menu-item {
            flex: 0 0 20%;
        }

        .pick-up {
            width: calc(100% - #{$padding * 2});
            margin: 0 auto;
        }
    }

    .timeline {
        display: flex;
        justify-content: flex-end;
    }

    $header-width: 4rem;
    $row-2-width: 2rem;
    $row-1-height: $header-width * 4.71;
    $row-2-height: $row-2-width;

    .timeline-inner {
        margin-left: 10%;

        width: 100%;

        max-width: calc(((100% - 1005px) / 2) + 1005px);

        max-height: 100vh;

        // border: 3px solid $color-3;
        // border-radius: 1rem;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        // grid-template-columns: $header-width auto;
        // grid-template-rows:  $row-2-width;
    }

    .row-1 {
        flex: 0 0 $row-1-height;
        display: flex;
    }

    .row-2 {
        flex: 0 0 $row-2-height;
        display: flex;
    }

    .timeline-header {
        flex: 0 0 $header-width;

        background-color: #dab142;
        position: relative;

        &.weeks {
            background: url("/assets/images/tasks/103/week.svg") right 1rem no-repeat #dab142;
            background-size: 50%;
        }

        &.years {
            background: url("/assets/images/tasks/103/year.svg") right 1rem no-repeat #dab142;
            background-size: 80%;
        }

        &.ages {
            background: url("/assets/images/tasks/103/ages.svg") right 1rem no-repeat #dab142;
            background-size: 80%;
        }

        &:before {
            content: "";
            position: absolute;
            width: 2px;
            height: 100%;

            background: #ede8da;

            top: 0;
            right: 0;
        }
    }

    .timeline-window-2 {
        flex: 1 1 auto;
        position: relative;
        overflow: hidden;
    }

    .timeline-window {

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        overflow-x: scroll;
        background: #ede8da;
    }

    .real-timeline {
        display: flex;
        height: 100%;

        .weeks {
            flex: 1 0 auto;
            display: flex;
            border-right: 1px solid #dab142;

            background: url("/assets/images/tasks/103/year-bg.svg") right 1rem no-repeat;
            background-size: auto 70%;
        }

        .years {
            flex: 1 0 auto;
            display: flex;

            background: url("/assets/images/tasks/103/ages-bg.svg") right 1rem no-repeat;
            background-size: auto 70%;
        }

        .ages {
            flex: 1 0 auto;
            display: flex;
        }
    }

    .time-zone {
        width: $header-width * 1.6;
        flex: 1 0 $header-width * 1.6;
        height: 100%;

        display: flex;
        flex-direction: column;

        .top {
            flex: 0 0 1rem;
            background: #dab142;
        }

        .cell {
            flex: 0 1 80%;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .label {
            background: #dab142;
            flex: 0 0 20%;
            flex-direction: column;

            color: white;

            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 2px solid $text-box-background;
            border-right: 2px solid $text-box-background;

            user-select: none;

            font-weight: 600;
            font-size: .8rem;

            span {
                display: inline-block;
                text-align: center;


                &.small {
                    margin-top: .5rem;
                    font-size: .7rem;
                }
            }
        }

        .spot {
            display: flex;
            justify-content: center;
            align-items: center;

            .drop-zone {
                min-width: $header-width * 1.45;
                height: $header-width * 1.45;
                border: 0.5rem solid #d6d09f;
                border-radius: 50%;

                text-align: center;

                background-color: #e5dfc3;

                display: flex;
                justify-content: center;
                align-items: center;

                .task-103-pick-up {
                    width: 50%;
                    height: 50%;
                }
            }

            &.with-item .drop-zone {
                border-color: #a59c38;
            }
        }
    }

    .spacer {
        flex: 0 0 $header-width;
    }

    .timeline-controlls {
        flex: 1 1 auto;
        position: relative;

        input {
            width: 100%;
            height: 100%;

            opacity: 0;
        }

        .marker {
            position: absolute;
            top: 50%;

            display: block;
            background: #735E23;
            pointer-events: none;
            width: 2rem;
            height: 1rem;

            border-radius: .5rem;

            margin-top: -.5rem;
        }

        &:before {
            content: '';

            display: block;
            width: 100%;
            background: white;
            position: absolute;
            height: 20%;
            top: 40%;
        }
    }

    .popup-overlay {
        img {
            display: block;
            margin: 1rem auto;
            max-width: 20%;
        }

        p {
            text-align: center;
        }
    }
}

.task-103-pick-up {
    display: block;
    max-width: 100%;

    cursor: grab;
}
