@import '../../variables.scss';

.award-page {
    .extra-background {
        background-image:
            url('/assets/images/naukowiec.png'),
            url('/assets/images/kapitan.png');
        background-position: 60% bottom, 42% bottom;
        background-repeat: no-repeat;

        background-size: 6%, 23%;
    }
}
