.game-end {
    pointer-events: all;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .logos {
        width: 80%;
        margin: 0 auto;

        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-content: space-between;
        align-items: center;

        .img {
            display: block;
            flex: 0 0 45%;

            img {
                width: 100%;
            }
        }
    }
}
