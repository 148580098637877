.compass {
    padding: 1rem 5% !important;

    p {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .5rem 0 0;

        span {
            display: inline-block;
        }
    }

    .distance {
        font-family: "citrus-gothic", "Inter", sans-serif;
        font-size: 2rem;
        padding: .2rem;

        .unit {
            font-size: .7em;
        }
    }

    .approaching {
        font-family: "citrus-gothic", "Inter", sans-serif;
        font-size: 2rem;
        margin: 0;
    }

    .hot {
        color: #bf4c1c;
    }

    .cold {
        color: #6488A0;
    }
}
