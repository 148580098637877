@import '../../../../variables.scss';

.task-402 {
    position: relative;

    .jupi-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;

        justify-content: center;
        align-items: center;

        .jupi {
            font-family: "citrus-gothic", "Inter", sans-serif;
            color: $color-3;
            font-size: 3rem;
            text-align: center;
            font-weight: 400;
        }
    }
}
