@import "./variables.scss";
@import "./fonts.scss";

@import-normalize;

body {
    height: 100%;
}

html {
    height: 100%;
    min-width: 320px;
}

html,
body,
div,
p,
a,
img,
li,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

html {
    font-size: 26px;

    @media (max-width: 1200px) {
        font-size: 22px;
    }

    @media (max-width: 850px) {
        font-size: 16px;
    }

    @media (max-width: 600px) {
        font-size: 14px;
    }
}

body {
    background: url("/assets/images/background.jpg") 50% 50% no-repeat $body-background-color;
    background-size: cover;
    background-attachment: fixed;

    font-family: "Inter", sans-serif;
    color: $font-color;
    font-weight: 300;

    &.no-image {
        background: $body-background-color;
    }
}

#root {
    min-height: 100%;
    height: 100%;
    position: relative;
}

h1 {
    font-family: "citrus-gothic", "Inter", sans-serif;
    font-size: 3.2rem;
    color: $header-color;
    font-weight: normal;
    margin: 1.5rem 0;
}

h2 {
    font-family: "citrus-gothic", "Inter", sans-serif;
    font-size: 1.2rem;
    color: $header-color;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0.5rem 0;
}

p {
    margin: 1.3em 0;
}

.page {
    min-height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button {
    font-family: "citrus-gothic", "Inter", sans-serif;
    text-transform: uppercase;

    display: inline-block;

    font-size: 1.5rem;

    padding: .4em 0.4em;
    border-radius: 0.6em;
    cursor: pointer;

    text-decoration: none;
    color: $button-text-color;
    background: $button-background-color;
    background-clip: padding-box;

    position: relative;
    border: transparent solid 0.15em;
    overflow: hidden;

    outline: none;

    text-align: center;

    width: auto;
    line-height: 1em;

    &:hover {
        box-shadow: 0 0 0 2px $button-background-color;
        border-color: white;
    }
}
