@import "../../../../variables.scss";

.task-301 {

    $bin-height: 8rem;

    .pickup-zone {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        background: $color-2;

        padding: 1rem 0 1.5rem;
        position: relative;

        .pick-up-container {
            flex: 0 0 16%;
            position: relative;

            .pick-up-container-inner {
                padding: 0 12%;
            }
        }


        .pick-up {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
        }
    }

    .game-row {
        display: flex;
        min-height: $bin-height;
        align-items: center;

        margin: 2rem 0;

        .drop-zone {
            flex: 0 0 36%;

            img {
                max-height: $bin-height;
                max-width: 40%;
                margin: 0 auto;
            }
        }
    }

    .game-row-text {

        border: solid 5px $color-3;
        border-radius: 2rem;
        background: $text-box-background;
        padding: 1rem;


        h3,p {
            margin: 0;
            padding: 0;

            font-size: 1rem;
        }

        h3 {
            font-weight: normal;
        }
    }
}

.task-301-pick-up {
    display: block;
    width: 100%;
}
