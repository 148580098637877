.geo-task {
    pointer-events: all;

    word-wrap: break-word;

    .buttons {
        display: flex;
        justify-content: center;

    }
}
