.no-position {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(0,0,0,.6);

    pointer-events: all;

    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 2.5rem;
        text-align: center;
    }
}
