@import '../../variables.scss';

.generator-widget {
    width: 100%;

    canvas {
        width: 100%;
    }

    .under {
        display: flex;

    }

    .controls {
        display: flex;
        justify-content: space-between;
        margin: 2.5rem 0;
        flex-wrap: wrap;
    }

    .controls-group {
        display: flex;
        flex-wrap: wrap;

        &>* {
            margin-right: 1rem;
        }
    }

    .achievements {
        flex: 0 0 32%;
        padding-right: 9%;

        .button {
            margin: 1rem 0;
        }
    }

    .acv-box {
        border: 10px solid $color-3;

        background: $text-box-background;

        padding: 1rem;
        border-radius: 1rem;

        display: flex;
        flex-wrap: wrap;

        justify-content: center;
    }

    .acv {
        flex: 0 0 50%;
    }

    .acv-wrapper {
        padding: 116% 0 0;
        position: relative;

        .acv-wrapper-2 {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            padding: 5%;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            width: 100%;
            display: block;
        }
    }

    .instruction {
        line-height: 1.4rem;

        padding-bottom: 2rem;
    }
}
