.task-102 {

    width: 100%;
    position: relative;

    .widget-container {
        display: block;
        margin: 0 auto;
        max-height: 95vh;
    }

    canvas {
        width: 100%;
    }
}
