@import '../../../../variables.scss';

.task-403 {
    .box-insert {
        width: 50%;
        margin: 0 auto;
    }

    .items {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        margin-top: 4rem;

        .item {

            display: flex;
            align-items: center;

            margin-bottom: .5rem;

            .index {
                flex: 0 0 3rem;
                text-align: center;
                font-family: "citrus-gothic", "Inter", sans-serif;
                font-size: 1.5rem;
            }

            .pick-up {
                position: relative;
                width: 100%;
            }
        }

        .drop-zone {
            height: 1rem;
            transition: height .1s;

            &.is-over {
                height: 4rem;
                transition: height 0.3s;
            }
        }
    }
}

.task-403-pick-up {
    width: 100%;
    display: flex;
    align-items: center;
    height: 3rem;

    padding: .5rem;
    border-radius: 1rem;

    &.correct {
        background-color: $correct-color;
    }

    img {
        flex: 0 0 3rem;
        height: 3rem;
    }

    .description {
        margin: 0 10% 0 5%;
        font-size: 0.8rem;
    }
}
